import React from 'react';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { isMobile } from './Common.js';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1, 2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: isMobile() ? "80%" : "50%",
  },
}));

const fontVariant = isMobile() ? "body1" : "h6";

function Technology() {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="overline" paragraph="true">
        [Tech]
      </Typography>
      <Typography variant={fontVariant} paragraph="true">
        Service Point Systems builds customer service products based on Near
        Field Communication, or NFC, technology.
      </Typography>
      <Typography variant={fontVariant} paragraph="true">
        NFC is a type of wireless communication that works at very short range.
        It allows devices to communicate with each other or with electronic
        "tags" which are typically stickers about an inch in diameter.  NFC is
        supported by most smartphones sold today and is commonly used for
         mobile payments.
      </Typography>
      <Typography variant={fontVariant} paragraph="true">
        Products sold by Service Point Systems allow customers needing
        assistance to scan NFC tags located at various business service points.
        When a tag is scanned, an application is launched to provide assistance.
      </Typography>
      <Typography variant={fontVariant}>
        Proprietary technology from Service Point Systems prevents spoofing.
        Alternative methods such as QR codes or unadorned NFC could allow
        anyone anywhere to pretend to be a real customer.
      </Typography>
    </Paper>
  );
}

export default Technology;
