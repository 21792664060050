import React from 'react';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { isMobile } from './Common.js';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1, 2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: isMobile() ? "80%" : "70%"
  },
  mobileList: {
    paddingLeft: theme.spacing(2),
  },
}));

const fontVariant = isMobile() ? "body1" : "h6";

function RestaurantFeedback() {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="overline" paragraph="true">
        [FEEDBACK FOR RESTAURANTS]
      </Typography>
      <Typography variant={fontVariant} paragraph="true">
        Feedback for Restaurants (FR) allows restaurant patrons to use their
        phones to provide feedback in the moment.  Following are some features
        and benefits:
      </Typography>
      <Typography variant={fontVariant}>
        <ul className={isMobile() ? classes.mobileList : {}}>
          <li>
            Customers do not need to install any software, do not need to
            exchange any information with staff, do not need to navigate
            anywhere on their phone or phone browser, and do not need to
            identify their table or location.
          </li>
          <li>
            It's easy and fast.  Customers just hold their phone near an
            electronic tag.  No addresses or URL's need to be typed.
          </li>
          <li>
            Unlike some other methods, management automatically knows the
            table from which the feedback originated making it possible
            to also know the server.
          </li>
          <li>
            Proprietary technology from Service Point Systems prevents
            fraudulent feedback.  Alternative methods such as QR codes
            or unadorned NFC could allow anyone anywhere to pretend to be
            a real customer.
          </li>
          <li>
            Data can be viewed/downloaded as TSV from browser or command-line.
          </li>
        </ul>
      </Typography>
    </Paper>
  );
}

export default RestaurantFeedback;
