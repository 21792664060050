import React from 'react';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { isMobile } from './Common.js';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1, 2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: isMobile() ? "80%" : "50%",
  },
}));

const fontVariant = isMobile() ? "body1" : "h6";

function About() {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="overline" paragraph="true">
        [About]
      </Typography>
      <Typography variant={fontVariant} paragraph="true">
        Life today moves faster than ever and every second is precious.
        Waiting on in-person service can be trying and even more so
        when you have information to provide but are unable.
        In an era where everybody is connected, it's still not easy to
        reach service personnel when needed.
      </Typography>
      <Typography variant={fontVariant} paragraph="true">
        After yet another lengthy wait for the check at a Seattle-area
        restaurant, Mitch decided there had to be a better way.  After
        much thinking and research, Service Point Systems was born.
      </Typography>
      <Typography variant={fontVariant}>
        Service Point Systems aims to make everyday life happier by saving
        time (and money) for businesses and their customers.  Mitch Vibbert
        is the founder and President of Service Point Systems.
      </Typography>
    </Paper>
  );
}

export default About;
