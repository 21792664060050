import React from 'react';
import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { isMobile } from './Common.js';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  breadcrumb: {
    marginTop: 16,
    marginLeft: 16,
  },
  text: {
    fontSize: 18,
  },
}));

const contact = '[Contact]';
const infoLabel = 'Sales and Information:';
const infoMail = 'info@servicepointsystems.com';
const infoMailTo = 'mailto:' + infoMail;
const supportLabel = 'Support:';
const supportMail = 'support@servicepointsystems.com';
const supportMailTo = 'mailto:' + supportMail;
const phoneLabel = 'Phone:';
const phone = "(800) 944-5341";

function Technology() {
  const classes = useStyles();

  if (isMobile()) {
    return (
      <>
      <Typography className={classes.breadcrumb} variant="overline" paragraph="true">
        {contact}
      </Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              {infoLabel}<br/>
              <a href={infoMailTo}>{infoMail}</a>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {supportLabel}<br/>
              <a href={supportMailTo}>{supportMail}</a>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {phoneLabel}<br/>
              {phone}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      </>
    );
  }

  return (
    <>
    <Typography className={classes.breadcrumb} variant="overline" paragraph="true">
      {contact}
    </Typography>
    <Table>
      <TableBody>
        <TableRow>
          <TableCell className={classes.text}>{infoLabel}</TableCell>
          <TableCell className={classes.text}><a href={infoMailTo}>{infoMail}</a></TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.text}>{supportLabel}</TableCell>
          <TableCell className={classes.text}><a href={supportMailTo}>{supportMail}</a></TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.text}>{phoneLabel}</TableCell>
          <TableCell className={classes.text}>{phone}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
    </>
  );
}

export default Technology;
