import React from 'react';
import { Route, Link as RouterLink, HashRouter } from 'react-router-dom';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { imagesPath, isMobile } from './Common';
import Typography from "@material-ui/core/Typography";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Home from "./Home";
import Technology from "./Technology";
import RestaurantServiceRequests from "./RestaurantServiceRequests";
import RestaurantFeedback from "./RestaurantFeedback";
import Contact from "./Contact";
import About from "./About";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        fontSize: isMobile() ? 13 : 14,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: isMobile() ? 13 : 14,
      },
    },
  }
});

const useStyles = makeStyles(theme => ({
  logo: {
    paddingLeft: isMobile() ? 18 : 0,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
  title: {
    fontSize: isMobile() ? 13 : 14,
  },
  flexDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toolbar: {
    paddingLeft: 14,
    paddingRight: 14,
  },
  button: {
    minHeight: 0,
    minWidth: 0,
    paddingLeft: isMobile() ? 7 : 14,
    paddingRight: isMobile() ? 7 : 14,
  },
  contact: {
    minHeight: 0,
    minWidth: 0,
    paddingLeft: isMobile() ? 5 : 14,
    paddingRight: isMobile() ? 5 : 14,
    marginLeft: 'auto'
  },
}));

function App() {
  const [productsAnchorEl, setProductsAnchorEl] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  function handleProductsClick(event) {
    setProductsAnchorEl(event.currentTarget);
  }

  function handleRightMenuClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleProductsClose() {
    setProductsAnchorEl(null);
  }

  function handleRightMenuClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <div className={classes.flexDiv}>
        <Typography className={classes.title} color="textPrimary">
          S&nbsp;E&nbsp;R&nbsp;V&nbsp;I&nbsp;C&nbsp;E&nbsp;&nbsp;&nbsp;&nbsp;
          P&nbsp;O&nbsp;I&nbsp;N&nbsp;T&nbsp;&nbsp;&nbsp;&nbsp;
          S&nbsp;Y&nbsp;S&nbsp;T&nbsp;E&nbsp;M&nbsp;S
        </Typography>
      </div>
      <HashRouter>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <ThemeProvider theme={theme}>
            <Button className={classes.button} color="inherit" component={RouterLink} to="/">HOME</Button>
            <Button className={classes.button} color="inherit" component={RouterLink} to="/technology">TECH</Button>
            <Button className={classes.button} color="inherit" onClick={handleProductsClick}>PRODUCTS</Button>
            <Menu
              anchorEl={productsAnchorEl}
              keepMounted
              open={Boolean(productsAnchorEl)}
              onClose={handleProductsClose}
            >
              <MenuItem component={RouterLink} to="/restaurantfeedback" onClick={handleProductsClose}>Feedback for Restaurants (FR)</MenuItem>
              <MenuItem component={RouterLink} to="/restaurantservicerequests" onClick={handleProductsClose}>Service Requests for Restaurants (SRR)</MenuItem>
            </Menu>
            <IconButton className={classes.contact} color="inherit" onClick={handleRightMenuClick}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleRightMenuClose}
            >
              <MenuItem component={RouterLink} to="/about" onClick={handleRightMenuClose}>ABOUT</MenuItem>
              <MenuItem component={RouterLink} to="/contact" onClick={handleRightMenuClose}>CONTACT</MenuItem>
            </Menu>
          </ThemeProvider>
        </Toolbar>
      </AppBar>
      <div>
        <Route exact path="/" component={Home}/>
        <Route path="/technology" component={Technology}/>
        <Route path="/restaurantservicerequests" component={RestaurantServiceRequests}/>
        <Route path="/restaurantfeedback" component={RestaurantFeedback}/>
        <Route path="/about" component={About}/>
        <Route path="/contact" component={Contact}/>
      </div>
      </HashRouter>
    </>
  );
}

export default App;
