import React from 'react';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { isMobile } from './Common.js';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1, 2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: isMobile() ? "80%" : "70%"
  },
  mobileList: {
    paddingLeft: theme.spacing(2),
  },
}));

const fontVariant = isMobile() ? "body1" : "h6";

function RestaurantServiceRequests() {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="overline" paragraph="true">
        [SERVICE REQUESTS FOR RESTAURANTS]
      </Typography>
      <Typography variant={fontVariant} paragraph="true">
        Service Requests for Restaurants (SRR) allows restaurant patrons to
        use their phones to communicate with waitstaff.  Following are some
        features and benefits:
      </Typography>
      <Typography variant={fontVariant} paragraph="true">
        <ul className={isMobile() ? classes.mobileList : {}}>
          <li>
            Customers do not need to install any software, do not need to 
            exchange any information with staff, do not need to navigate 
            anywhere on their phone or phone browser, and do not need to 
            identify their table or location.
          </li>
          <li>
            It's easy and fast.  Customers just hold their phone near an
            electronic tag.  No addresses or URL's need to be typed.
          </li>
          <li>
            Customers can ask for whatever they need right away, saving
            time for both waitstaff and customer.
          </li>
          <li>
            Reduced wait times keeps customers coming back.
          </li>
          <li>
            Patrons purchase more food and drinks than they would otherwise
            since they can request additional items before they have a chance
            to change their minds while waiting on their server.
          </li>
          <li>
            Proprietary technology from Service Point Systems prevents
            fraudulent service requests.  Alternative methods such
            as QR codes or unadorned NFC could allow anyone anywhere to
            pretend to be a real customer.
          </li>
          <li>
            Data can be downloaded to a TSV file for subsequent analysis.
          </li>
        </ul>
      </Typography>
      <Typography variant={fontVariant} paragraph="true">
        Below is one possible scenario:
      </Typography>
      <Typography variant={fontVariant}>
        <ol className={isMobile() ? classes.mobileList : {}}>
          <li>
            When sitting down, a customer notices an NFC tag and simple
            instructions at the table.</li>
          <li>
            After the food is delivered, the customer realizes he/she needs
            ketchup.
          </li>
          <li>
            The customer holds his/her smartphone near the NFC tag located at
            the table.
          </li>
          <li>
            A messaging application automatically launches on the customer’s
            phone.
          </li>
          <li>
            The NFC tag already identifies the table, so the customer just
            enters his/her request for ketchup and taps the SEND button.
          </li>
          <li>
            The customer’s message appears on one or more displays in
            restaurant service areas.
          </li>
          <li>
            A server, not necessarily the patron's server, spots the request
            on a display and delivers the ketchup, or the server calls out the
            request to another server to deliver the ketchup.
          </li>
        </ol>
      </Typography>
    </Paper>
  );
}

export default RestaurantServiceRequests;
