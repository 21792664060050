import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { imagesPath, isMobile } from './Common';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  center: {
    position: 'absolute',
    top: '50%', 
    left: '50%',
    transform: isMobile() ? 'translate(-50%, -50%)' : 'translate(-50%, -20%)',
  },
  blurb: {
    color: 'white',
    fontSize: isMobile() ? 18 : 26,
  },
  patent: {
    color: 'white',
    fontSize: isMobile() ? 12 : 16,
  },
  zeroFontSize: {
    fontSize: 0,
  },
}));

function Home() {
  const classes = useStyles();

  return (
    <div className={classes.zeroFontSize}>
      <img src={imagesPath + "circuit.jpg"} alt="circuit" width="100%"/>
      <div className={classes.center}>
        { !isMobile() &&
          <Typography className={classes.blurb} align="center">
            A new era in customer service technology.
          </Typography>
        }
        { isMobile() &&
          <>
          <Typography className={classes.blurb} align="center">
            A new era in
          </Typography>
          <Typography className={classes.blurb} align="center">
            customer service
          </Typography>
          <Typography className={classes.blurb} align="center">
            technology.
          </Typography>
          </>
        }
        <Typography className={classes.patent} align={isMobile() ? "center" : "center"}>
          <br/><br/>Patent pending.
        </Typography>
      </div>
      { isMobile() &&
        <>
          <img src={imagesPath + "circuit.jpg"} alt="circuit" width="100%"/>
          <img src={imagesPath + "circuit.jpg"} alt="circuit" width="100%"/>
        </>
      }
    </div>
  );
}

export default Home;
